/* SocialMediaLinks.css */
.social-media-frame {
    background-color: #1e1e1e; /* Dark background for the frame */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding inside the frame */
    margin-top: 20px; /* Space above social media frame */
  }
  
  .social-media-title {
    color: #3bb78f; /* Title color */
    margin-bottom: 10px; /* Space below the title */
    font-size: 1.5em; /* Title font size */
    text-align: center; /* Center align title */
  }
  
  .social-media-container {
    display: flex; /* Use flexbox for layout */
    justify-content: space-around; /* Space links evenly */
    align-items: center; /* Center items vertically */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    gap: 10px; /* Space between links */
  }
  
  .social-link {
    text-decoration: none; /* Remove underline */
    color: #fff; /* White color for text */
    transition: color 0.3s; /* Smooth color transition */
    display: flex; /* Use flex to align icon and text */
    align-items: center; /* Center align icon and text */
  }
  
  .social-link:hover {
    color: #3bb78f; /* Change color on hover */
  }
  
  .social-link svg {
    margin-right: 5px; /* Space between icon and text */
  }
  
  .contact-info {
    font-size: 0.9em; /* Smaller font size for contact info */
    color: #e0e0e0; /* Light color for text */
    text-align: center; /* Center align contact info */
    margin-top: 15px; /* Space above contact info */
  }
  
  .email-link {
    text-decoration: none; /* Remove underline */
    color: #3bb78f; /* Color for the email link */
    margin-left: 5px; /* Space between text and email */
  }
  
  .email-link:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .social-media-container {
      flex-direction: column; /* Stack links vertically on small screens */
      align-items: center; /* Center items horizontally */
    }
  
    .social-link {
      margin: 5px 0; /* Space between links when stacked */
    }
  }