/* Existing CSS */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  color: #ffffff;
  margin-top: -30px;
  margin-bottom: 30px;
  font-size: 2.5em;
  font-weight: 300;
}

.content {
  padding-left: 20px;
}

.description1 {
  margin-bottom: 20px;
  line-height: 1.6;
  font-size: 1.1em;
}

.image-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.image {
  max-width: 30%;
  height: auto;
  display: block;
  border-radius: 8px;
  border: 2px solid #3bb78f;
}

.description2 {
  line-height: 1.6;
  font-size: 1.1em;
}

blockquote {
  border-left: 4px solid #3bb78f;
  padding-left: 16px;
  margin: 20px 0;
}
.red-blockquote {
  border-left: 4px solid #FF0000;
}

.donation-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.donations-section {
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 15px;
  width: 45%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.donation-section {
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.donation-section h2 {
  color: #3bb78f;
  margin-bottom: 15px;
}

.donations-section h2 {
  color: #3bb78f;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.progress-bar {
  background-color: #3e3e3e;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  margin-bottom: 15px;
}

.progress {
  background-color: #3bb78f;
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.donation-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.amount-raised, .goal-amount {
  font-size: 1.1em;
}

.highlight {
  font-weight: bold;
  color: #3bb78f;
}

.donate-button {
  background-color: #3bb78f;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.donate-button:hover {
  background-color: #36a76a;
}

.donations-list {
  margin-top: 20px;
  background-color: #1e1e1e; /* Dark background for the list */
  border-radius: 8px;
  padding: 10px;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

.donation-item {
  background-color: #2a2a2a;
  border-radius: 3px;
  padding: 4px 6px;
  margin-bottom: 5px;
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.donation-name {
  font-weight: bold;
  color: #3bb78f;
  flex: 1; /* Allow the name to grow */
  min-width: 150px; /* Fixed minimum width for name */
}

.donation-date {
  font-size: 0.75em;
  color: #a0a0a0;
  margin-left: 5px; /* Adjusted margin for better alignment */
  text-align: left; /* Change to left align */
}

.donation-amount {
  font-weight: bold;
  color: #ffffff;
  min-width: 80px; /* Reduced minimum width to prevent overflow */
  text-align: right; /* Align text to the right */
}

.donation-item:last-child {
  margin-bottom: 0;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.back-button, .next-button {
  background-color: #3bb78f;
  color: white;
  border: none;
  padding: 6px 12px;
  font-size: 0.9em;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover, .next-button:hover {
  background-color: #36a76a;
}

.back-button:disabled, .next-button:disabled {
  background-color: #1e1e1e;
  cursor: not-allowed;
}

.pagination-info {
  color: #e0e0e0;
  font-size: 0.8em;
}

/* Mobile responsiveness */
@media (max-width: 1080px) {
  .description2 {
    max-width: 100%; /* Allow full width on small devices */
  }
  .image-container {
    flex-direction: column;
  }

  .image {
    max-width: 100%;
  }

  .donation-container {
    flex-direction: column;
  }

  .donation-section, .donations-section {
    width: 90%;
  }

  .donation-date {
    width: auto; /* Allow date to take up automatic space */
    text-align: left; /* Ensure left alignment for date */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .donation-item {
    font-size: 0.8em; /* Reduce font size for smaller screens */
  }

  .donation-date {
    font-size: 1em; /* Smaller font size for the date */
  }

  .donation-amount {
    font-size: 1em; /* Smaller font size for the amount */
    min-width: 60px; /* Adjust minimum width for smaller screens */
  }
}

@media (max-width: 480px) {
  .donation-item {
    font-size: 0.7em; /* Further reduce font size for very small screens */
  }

  .donation-date {
    font-size: 0.65em; /* Even smaller font size for the date */
  }

  .donation-amount {
    font-size: 0.85em; /* Even smaller font size for the amount */
    min-width: 50px; /* Further adjust minimum width */
  }
}