.thankYouPage {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f2429;
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.thankYouContainer {
  text-align: center;
}

.thankYouMessage {
  font-size: 3rem;
  margin-bottom: 20px;
}

.returnHomeButton {
  padding: 10px 20px;
  font-size: 1.5rem;
  color: #fff;
  background-color: #3bb78f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.returnHomeButton:hover {
  background-color: #36a76a;
}
